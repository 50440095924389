<template>
  <div v-if="mVoucher">
    <div class="tabs is-boxed">
      <ul>
        <li :class="{ 'is-active': tabIndex === 0 }">
          <a @click="tabIndex = 0">
            <span v-text="$t('Components.Vouchers.BasicData.Section_Title')"></span>
          </a>
        </li>
        <li :class="{ 'is-active': tabIndex === 1 }">
          <a @click="tabIndex = 1">
            <span v-text="$t('Components.Vouchers.Settings.Section_Title')"></span>
          </a>
        </li>
      </ul>
    </div>
    <div class="has-margin-bottom-x2">
      <transition
        name="slide-fade"
        mode="out-in"
      >
        <section
          v-if="tabIndex === 0"
          key="basic"
          class="section is-small pl-0 pr-0"
        >
          <div
            class="title is-6"
            v-text="$t('Components.Vouchers.BasicData.Section_Title')"
          ></div>
          <table class="table is-striped is-fullwidth has-margin-bottom-x2">
            <tbody>
              <tr>
                <td>{{ $t('Components.Vouchers.BasicData.Label_Name') }}</td>
                <td>{{ mVoucher.Name }}</td>
              </tr>
              <tr>
                <td>{{ $t('Components.Vouchers.BasicData.Label_Type') }}</td>
                <td>{{ mVoucher.Type }}</td>
              </tr>
              <tr>
                <td>{{ $t('Components.Vouchers.BasicData.Label_Code') }}</td>
                <td>{{ mVoucher.Code }}</td>
              </tr>
              <tr>
                <td>
                  {{ $t('Components.Vouchers.BasicData.Label_Description') }}
                </td>
                <td>{{ mVoucher.Description }}</td>
              </tr>
            </tbody>
          </table>

          <div
            class="title is-6"
            v-text="$t('Components.Vouchers.BasicData.Section_Title_Other')"
          ></div>
          <table class="table is-striped is-fullwidth">
            <tbody>
              <tr>
                <td v-text="
                    $tc(
                      'Components.Vouchers.BasicData.Text_Stats_OptionsAdded',
                      linkedOptions
                    )
                  "></td>
              </tr>
              <tr>
                <td v-text="
                    $tc(
                      'Components.Vouchers.BasicData.Text_Stats_CompaniesAdded',
                      linkedCompanies
                    )
                  "></td>
              </tr>
              <tr>
                <td v-text="
                    $tc(
                      'Components.Vouchers.BasicData.Text_Stats_ProfilesAdded',
                      linkedProfiles
                    )
                  "></td>
              </tr>
              <tr>
                <td v-text="
                    $tc(
                      'Components.Vouchers.BasicData.Text_Stats_LocationsAdded',
                      linkedLocations
                    )
                  "></td>
              </tr>
              <tr>
                <td v-text="
                    $tc(
                      'Components.Vouchers.BasicData.Text_Stats_PriceSettingsAdded',
                      priceRules
                    )
                  "></td>
              </tr>
            </tbody>
          </table>
        </section>

        <section
          v-if="tabIndex === 1"
          key="settings"
          class="section is-small pl-0 pr-0"
        >
          <div
            class="title is-6"
            v-text="$t('Components.Vouchers.Settings.Section_Title')"
          ></div>
          <table class="table is-striped is-fullwidth">
            <tbody>
              <tr>
                <td>
                  {{
                    $t(
                      'Components.Vouchers.Settings.Label_VoucherOncePerPerson'
                    )
                  }}
                </td>
                <td>{{ $t(`General.Yes`) }}</td>
              </tr>
              <tr>
                <td>{{ $t('Components.Vouchers.Settings.Label_Credit') }}</td>
                <td>{{ mVoucher.Credit }}</td>
              </tr>
              <tr>
                <td>
                  {{ $t('Components.Vouchers.Settings.Label_SeatsAmount') }}
                </td>
                <td>{{ mVoucher.SeatsCount }}</td>
              </tr>
              <tr>
                <td>
                  {{ $t('Components.Vouchers.Settings.Label_UsedWhenBooking') }}
                </td>
                <td>
                  <div
                    v-for="meetingtype in locationState.Meetingtypes"
                    :key="meetingtype.MeetingtypeId"
                  >
                    <span class="main">{{
                        $t(
                          `General.MeetingType${meetingtype.MeetingtypeId}_Name`
                        )
                      }}:</span>
                    <span class="icon has-padding-left">
                      <font-awesome-icon
                        v-if="
                          checkMeetingtypeInVoucher(meetingtype.MeetingtypeId)
                        "
                        :icon="['fas', 'check']"
                        class="has-text-success"
                      />
                      <font-awesome-icon
                        v-if="
                          !checkMeetingtypeInVoucher(meetingtype.MeetingtypeId)
                        "
                        :icon="['fas', 'times']"
                        class="has-text-danger"
                      />
                    </span>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  {{ $t('Components.Vouchers.Settings.Label_ValidityPeriod') }}
                </td>
                <td>
                  {{
                    mVoucher.ValidFrom
                      | dateFormat($i18n.locale, 'shortDateFormat')
                  }}
                  -
                  {{
                    mVoucher.ValidUntil
                      | dateFormat($i18n.locale, 'shortDateFormat')
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('Components.Vouchers.Settings.Label_MinimalSeats') }}
                </td>
                <td>{{ mVoucher.MinSeats }}</td>
              </tr>
              <tr>
                <td>
                  {{ $t('Components.Vouchers.Settings.Label_MaximalSeats') }}
                </td>
                <td>{{ mVoucher.MaxSeats }}</td>
              </tr>
              <tr>
                <td>
                  {{ $t('Components.Vouchers.Settings.Label_MinimalHours') }}
                </td>
                <td>{{ mVoucher.MinHours }}</td>
              </tr>
              <tr>
                <td>
                  {{ $t('Components.Vouchers.Settings.Label_MaximalHours') }}
                </td>
                <td>{{ mVoucher.MaxHours }}</td>
              </tr>
              <tr>
                <td>{{ $t('Components.Vouchers.Settings.Label_Days') }}</td>
                <td class="tableCell-days">
                  <div>
                    <span>{{
                      $t('Components.Vouchers.Settings.Label_Monday')
                    }}</span>
                    <font-awesome-icon
                      v-if="voucherDayActive(2)"
                      :icon="['fas', voucherDayActive(2) ? 'check' : 'times']"
                      :class="
                        voucherDayActive(2)
                          ? 'has-text-success'
                          : 'has-text-danger'
                      "
                    />
                  </div>

                  <div>
                    <span>{{
                      $t('Components.Vouchers.Settings.Label_Tuesday')
                    }}</span>
                    <font-awesome-icon
                      v-if="voucherDayActive(3)"
                      :icon="['fas', voucherDayActive(3) ? 'check' : 'times']"
                      :class="
                        voucherDayActive(3)
                          ? 'has-text-success'
                          : 'has-text-danger'
                      "
                    />
                  </div>

                  <div>
                    <span>{{
                      $t('Components.Vouchers.Settings.Label_Wednesday')
                    }}</span>
                    <font-awesome-icon
                      v-if="voucherDayActive(4)"
                      :icon="['fas', voucherDayActive(4) ? 'check' : 'times']"
                      :class="
                        voucherDayActive(4)
                          ? 'has-text-success'
                          : 'has-text-danger'
                      "
                    />
                  </div>

                  <div>
                    <span>{{
                      $t('Components.Vouchers.Settings.Label_Thursday')
                    }}</span>
                    <font-awesome-icon
                      v-if="voucherDayActive(5)"
                      :icon="['fas', voucherDayActive(5) ? 'check' : 'times']"
                      :class="
                        voucherDayActive(5)
                          ? 'has-text-success'
                          : 'has-text-danger'
                      "
                    />
                  </div>

                  <div>
                    <span>{{
                      $t('Components.Vouchers.Settings.Label_Friday')
                    }}</span>
                    <font-awesome-icon
                      v-if="voucherDayActive(6)"
                      :icon="['fas', voucherDayActive(6) ? 'check' : 'times']"
                      :class="
                        voucherDayActive(6)
                          ? 'has-text-success'
                          : 'has-text-danger'
                      "
                    />
                  </div>

                  <div>
                    <span>{{
                      $t('Components.Vouchers.Settings.Label_Saturday')
                    }}</span>
                    <font-awesome-icon
                      v-if="voucherDayActive(7)"
                      :icon="['fas', voucherDayActive(7) ? 'check' : 'times']"
                      :class="
                        voucherDayActive(7)
                          ? 'has-text-success'
                          : 'has-text-danger'
                      "
                    />
                  </div>

                  <div>
                    <span>{{
                      $t('Components.Vouchers.Settings.Label_Sunday')
                    }}</span>
                    <font-awesome-icon
                      v-if="voucherDayActive(1)"
                      :icon="['fas', voucherDayActive(1) ? 'check' : 'times']"
                      :class="
                        voucherDayActive(1)
                          ? 'has-text-success'
                          : 'has-text-danger'
                      "
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </transition>
    </div>
    <div class="has-margin-bottom has-text-right">
      <a
        @click="goToVoucher()"
        class="button is-primary is-small"
        v-text="$t('Views.Vouchers.Vouchers.Button_OpenVoucher')"
      >
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import voucherProvider from '@/providers/voucher'

export default {
  props: {
    voucher: {
      default: null,
      type: Object,
    },
    voucherId: {
      default: 0,
      type: Number,
    },
    locationId: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      mVoucher: this.voucher,
      mVoucherId: this.voucherId,
      tabIndex: 0,
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
    linkedCompanies() {
      return this.mVoucher.Companies ? this.mVoucher.Companies.length : 0
    },
    linkedLocations() {
      return this.mVoucher.Locations ? this.mVoucher.Locations.length : 0
    },
    linkedOptions() {
      //return (this.mVoucher.Options) ? this.mVoucher.Options.length : 0
      return this.mVoucher.Options.filter(
        (o) => o.LocationId === this.locationState.Id
      ).length
    },
    linkedProfiles() {
      return this.mVoucher.Profiles ? this.mVoucher.Profiles.length : 0
    },
    priceRules() {
      //return (this.mVoucher.Prices) ? this.mVoucher.Prices.length : 0
      return this.mVoucher.Prices.filter(
        (p) => p.LocationId === this.locationState.Id
      ).length
    },
  },

  created() {
    // if (!this.mVoucher) {
    this.getVoucherData()
    // }
  },

  methods: {
    getVoucherData() {
      let self = this
      voucherProvider.methods
        .getVoucherById(this.mVoucherId)
        .then((response) => {
          if (response.status === 200) {
            self.mVoucher = response.data
          }
        })
    },

    checkMeetingtypeInVoucher(meetingtypeId) {
      let result = false

      if (this.mVoucher) {
        let typeIndex = this.mVoucher.MeetingtypeIds.findIndex(
          (m) => m === meetingtypeId
        )
        if (typeIndex > -1) {
          result = true
        }
      }

      return result
    },

    voucherDayActive(day) {
      let dayIndex = this.mVoucher.Days.findIndex((d) => d === day)
      return dayIndex > -1
    },

    goToVoucher() {
      let self = this
      this.$emit('hideDrawer')
      let t = setTimeout(() => {
        if (this.locationId > 0) {
          this.$router.push({
            name: `location-vouchers-detail`,
            params: { voucherId: this.mVoucher.Id },
          })
        } else {
          this.$router.push({
            name: `vouchers-detail`,
            params: { voucherId: this.mVoucher.Id },
          })
        }
        clearTimeout(t)
      }, 200)
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  td {
    &:first-child {
      width: 140px;
    }

    &.tableCell-days {
      div {
        display: flex;
        span {
          flex-grow: 1;
        }
      }
    }
  }
}
</style>
