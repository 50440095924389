var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.mVoucher)?_c('div',[_c('div',{staticClass:"tabs is-boxed"},[_c('ul',[_c('li',{class:{ 'is-active': _vm.tabIndex === 0 }},[_c('a',{on:{"click":function($event){_vm.tabIndex = 0}}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('Components.Vouchers.BasicData.Section_Title'))}})])]),_c('li',{class:{ 'is-active': _vm.tabIndex === 1 }},[_c('a',{on:{"click":function($event){_vm.tabIndex = 1}}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('Components.Vouchers.Settings.Section_Title'))}})])])])]),_c('div',{staticClass:"has-margin-bottom-x2"},[_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[(_vm.tabIndex === 0)?_c('section',{key:"basic",staticClass:"section is-small pl-0 pr-0"},[_c('div',{staticClass:"title is-6",domProps:{"textContent":_vm._s(_vm.$t('Components.Vouchers.BasicData.Section_Title'))}}),_c('table',{staticClass:"table is-striped is-fullwidth has-margin-bottom-x2"},[_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('Components.Vouchers.BasicData.Label_Name')))]),_c('td',[_vm._v(_vm._s(_vm.mVoucher.Name))])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('Components.Vouchers.BasicData.Label_Type')))]),_c('td',[_vm._v(_vm._s(_vm.mVoucher.Type))])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('Components.Vouchers.BasicData.Label_Code')))]),_c('td',[_vm._v(_vm._s(_vm.mVoucher.Code))])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t('Components.Vouchers.BasicData.Label_Description'))+" ")]),_c('td',[_vm._v(_vm._s(_vm.mVoucher.Description))])])])]),_c('div',{staticClass:"title is-6",domProps:{"textContent":_vm._s(_vm.$t('Components.Vouchers.BasicData.Section_Title_Other'))}}),_c('table',{staticClass:"table is-striped is-fullwidth"},[_c('tbody',[_c('tr',[_c('td',{domProps:{"textContent":_vm._s(
                  _vm.$tc(
                    'Components.Vouchers.BasicData.Text_Stats_OptionsAdded',
                    _vm.linkedOptions
                  )
                )}})]),_c('tr',[_c('td',{domProps:{"textContent":_vm._s(
                  _vm.$tc(
                    'Components.Vouchers.BasicData.Text_Stats_CompaniesAdded',
                    _vm.linkedCompanies
                  )
                )}})]),_c('tr',[_c('td',{domProps:{"textContent":_vm._s(
                  _vm.$tc(
                    'Components.Vouchers.BasicData.Text_Stats_ProfilesAdded',
                    _vm.linkedProfiles
                  )
                )}})]),_c('tr',[_c('td',{domProps:{"textContent":_vm._s(
                  _vm.$tc(
                    'Components.Vouchers.BasicData.Text_Stats_LocationsAdded',
                    _vm.linkedLocations
                  )
                )}})]),_c('tr',[_c('td',{domProps:{"textContent":_vm._s(
                  _vm.$tc(
                    'Components.Vouchers.BasicData.Text_Stats_PriceSettingsAdded',
                    _vm.priceRules
                  )
                )}})])])])]):_vm._e(),(_vm.tabIndex === 1)?_c('section',{key:"settings",staticClass:"section is-small pl-0 pr-0"},[_c('div',{staticClass:"title is-6",domProps:{"textContent":_vm._s(_vm.$t('Components.Vouchers.Settings.Section_Title'))}}),_c('table',{staticClass:"table is-striped is-fullwidth"},[_c('tbody',[_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t( 'Components.Vouchers.Settings.Label_VoucherOncePerPerson' ))+" ")]),_c('td',[_vm._v(_vm._s(_vm.$t(`General.Yes`)))])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('Components.Vouchers.Settings.Label_Credit')))]),_c('td',[_vm._v(_vm._s(_vm.mVoucher.Credit))])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t('Components.Vouchers.Settings.Label_SeatsAmount'))+" ")]),_c('td',[_vm._v(_vm._s(_vm.mVoucher.SeatsCount))])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t('Components.Vouchers.Settings.Label_UsedWhenBooking'))+" ")]),_c('td',_vm._l((_vm.locationState.Meetingtypes),function(meetingtype){return _c('div',{key:meetingtype.MeetingtypeId},[_c('span',{staticClass:"main"},[_vm._v(_vm._s(_vm.$t( `General.MeetingType${meetingtype.MeetingtypeId}_Name` ))+":")]),_c('span',{staticClass:"icon has-padding-left"},[(
                        _vm.checkMeetingtypeInVoucher(meetingtype.MeetingtypeId)
                      )?_c('font-awesome-icon',{staticClass:"has-text-success",attrs:{"icon":['fas', 'check']}}):_vm._e(),(
                        !_vm.checkMeetingtypeInVoucher(meetingtype.MeetingtypeId)
                      )?_c('font-awesome-icon',{staticClass:"has-text-danger",attrs:{"icon":['fas', 'times']}}):_vm._e()],1)])}),0)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t('Components.Vouchers.Settings.Label_ValidityPeriod'))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm.mVoucher.ValidFrom,_vm.$i18n.locale, 'shortDateFormat'))+" - "+_vm._s(_vm._f("dateFormat")(_vm.mVoucher.ValidUntil,_vm.$i18n.locale, 'shortDateFormat'))+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t('Components.Vouchers.Settings.Label_MinimalSeats'))+" ")]),_c('td',[_vm._v(_vm._s(_vm.mVoucher.MinSeats))])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t('Components.Vouchers.Settings.Label_MaximalSeats'))+" ")]),_c('td',[_vm._v(_vm._s(_vm.mVoucher.MaxSeats))])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t('Components.Vouchers.Settings.Label_MinimalHours'))+" ")]),_c('td',[_vm._v(_vm._s(_vm.mVoucher.MinHours))])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t('Components.Vouchers.Settings.Label_MaximalHours'))+" ")]),_c('td',[_vm._v(_vm._s(_vm.mVoucher.MaxHours))])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('Components.Vouchers.Settings.Label_Days')))]),_c('td',{staticClass:"tableCell-days"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('Components.Vouchers.Settings.Label_Monday')))]),(_vm.voucherDayActive(2))?_c('font-awesome-icon',{class:_vm.voucherDayActive(2)
                        ? 'has-text-success'
                        : 'has-text-danger',attrs:{"icon":['fas', _vm.voucherDayActive(2) ? 'check' : 'times']}}):_vm._e()],1),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('Components.Vouchers.Settings.Label_Tuesday')))]),(_vm.voucherDayActive(3))?_c('font-awesome-icon',{class:_vm.voucherDayActive(3)
                        ? 'has-text-success'
                        : 'has-text-danger',attrs:{"icon":['fas', _vm.voucherDayActive(3) ? 'check' : 'times']}}):_vm._e()],1),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('Components.Vouchers.Settings.Label_Wednesday')))]),(_vm.voucherDayActive(4))?_c('font-awesome-icon',{class:_vm.voucherDayActive(4)
                        ? 'has-text-success'
                        : 'has-text-danger',attrs:{"icon":['fas', _vm.voucherDayActive(4) ? 'check' : 'times']}}):_vm._e()],1),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('Components.Vouchers.Settings.Label_Thursday')))]),(_vm.voucherDayActive(5))?_c('font-awesome-icon',{class:_vm.voucherDayActive(5)
                        ? 'has-text-success'
                        : 'has-text-danger',attrs:{"icon":['fas', _vm.voucherDayActive(5) ? 'check' : 'times']}}):_vm._e()],1),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('Components.Vouchers.Settings.Label_Friday')))]),(_vm.voucherDayActive(6))?_c('font-awesome-icon',{class:_vm.voucherDayActive(6)
                        ? 'has-text-success'
                        : 'has-text-danger',attrs:{"icon":['fas', _vm.voucherDayActive(6) ? 'check' : 'times']}}):_vm._e()],1),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('Components.Vouchers.Settings.Label_Saturday')))]),(_vm.voucherDayActive(7))?_c('font-awesome-icon',{class:_vm.voucherDayActive(7)
                        ? 'has-text-success'
                        : 'has-text-danger',attrs:{"icon":['fas', _vm.voucherDayActive(7) ? 'check' : 'times']}}):_vm._e()],1),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('Components.Vouchers.Settings.Label_Sunday')))]),(_vm.voucherDayActive(1))?_c('font-awesome-icon',{class:_vm.voucherDayActive(1)
                        ? 'has-text-success'
                        : 'has-text-danger',attrs:{"icon":['fas', _vm.voucherDayActive(1) ? 'check' : 'times']}}):_vm._e()],1)])])])])]):_vm._e()])],1),_c('div',{staticClass:"has-margin-bottom has-text-right"},[_c('a',{staticClass:"button is-primary is-small",domProps:{"textContent":_vm._s(_vm.$t('Views.Vouchers.Vouchers.Button_OpenVoucher'))},on:{"click":function($event){return _vm.goToVoucher()}}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }